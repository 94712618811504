<template>
    <LiefengContent :isBack="true" @backClick="backClick">
        <template v-slot:title>积分规则设置</template>
        <template v-slot:toolsbarLeft>
            <Select v-if="!$route.query.orgCode || $route.query.orgCode == ''" v-model="searchModel.poolId" style="width: 120px; margin-right: 5px" placeholder="应用模式" clearable>
                <Option :value="item.poolId" v-for="(item, index) in selectList" :key="index">{{ item.poolName }}</Option>
            </Select>
            <Select v-model="searchModel.taskType" style="width: 120px; margin-right: 5px" placeholder="所属类型" clearable>
                <Option :value="item.dictKey" v-for="(item, index) in typeList" :key="index">{{ item.dictValue }}</Option>
            </Select>
            <Button
                type="primary"
                @click="
                    () => {
                        getList()
                    }
                "
            >
                查询
            </Button>
            <Button
                type="info"
                style="margin-left: 10px"
                @click="
                    () => {
                        searchModel = {
                            poolId: '',
                            taskType: '',
                        }
                        getList()
                    }
                "
            >
                重置
            </Button>
        </template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增任务</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="tableColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>

            <!-- 新增修改弹窗 -->
            <LiefengModal class="map" :fullscreen="false" :title="currentFormData.taskId ? '修改' : '新增'" :value="saveStatus" @input="saveStatusFn" :width="500" height="700px">
                <template v-slot:contentarea>
                    <div style="height: 100%; overflow: hidden">
                        <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                            <FormItem label="任务名称" prop="taskName">
                                <Input v-model.trim="currentFormData.taskName" placeholder="请输入名称" maxlength="50"></Input>
                            </FormItem>
                            <FormItem label="描述">
                                <Input type="textarea" rows="8" v-model.trim="currentFormData.taskDesc" placeholder="简单描述"></Input>
                            </FormItem>
                            <FormItem label="应用模式">
                                <RadioGroup v-model="currentFormData.poolId" @on-change="setPoolId">
                                    <Radio v-for="(item, index) in selectList" :key="index" :label="item.poolId">{{ item.poolName }}</Radio>
                                </RadioGroup>
                            </FormItem>
                            <FormItem label="所属类型">
                                <RadioGroup v-model="currentFormData.taskType">
                                    <Radio v-for="(item, index) in typeList" :key="index" :label="item.dictKey">{{ item.dictValue }}</Radio>
                                </RadioGroup>
                            </FormItem>
                            <FormItem label="输入类型">
                                <RadioGroup v-model="currentFormData.incomeType" @on-change="changeIncome">
                                    <Radio v-for="(item, index) in incomeList" :key="index" :label="item.dictKey">{{ item.dictValue }}</Radio>
                                </RadioGroup>
                            </FormItem>
                            <FormItem label="计算规则" v-if="currentFormData.poolId && currentFormData.poolId != '' && currentFormData.incomeType && currentFormData.incomeType != ''">
                                <span style="margin: 0 5px" v-if="currentFormData.incomeType != '3'">每{{incomeName}}得</span>
                                <Input style="width: 100px" v-if="currentFormData.incomeType != '3'" type="Number" v-model.trim="currentFormData.dayMaxCount"></Input>
                                <span style="margin: 0 5px" v-if="currentFormData.incomeType != '3'">{{ seletName }}，每天最多</span>

                                <span style="margin: 0 5px" v-if="currentFormData.incomeType == '3'">用于填写积分数量的场景,每天最多</span>
                                <Input style="width: 100px" v-if="currentFormData.incomeType != '3'" type="Number" v-model.trim="currentFormData.sequenceNum"></Input>
                                <Input style="width: 100px" v-if="currentFormData.incomeType == '3'" type="Number" v-model.trim="currentFormData.dayMaxCount"></Input>
                                <span style="margin: 0 5px">{{incomeName}}</span>
                            </FormItem>
                            <FormItem label="关联业务点">
                                <Select v-model="currentFormData.eventId" style="width: 120px; margin-right: 5px" placeholder="选择业务点" clearable>
                                    <Option :value="item.eventId" v-for="(item, index) in pointList" :key="index">{{ item.eventName }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="跳转链接">
                                <Input style="width: 300px" v-model.trim="currentFormData.herf" placeholder="设置跳转页面链接"></Input>
                            </FormItem>
                        </Form>
                    </div>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="saveStatusFn(false)">取消</Button>
                    <Button type="primary" @click="submit">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUpload from "@/components/LiefengUpload"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUpload,
    },
    data() {
        return {
            searchModel: {
                poolId: "",
                taskType: "",
            },
            searchList: [],
            tableData: [],
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    title: "任务名称",
                    width: 250,
                    key: "taskName",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: { color: "blue", cursor: "pointer" },
                                on: {
                                    click: () => {
                                        this.selectTask(params.row.taskId)
                                    },
                                },
                            },
                            params.row.taskName
                        )
                    },
                },
                {
                    title: "描述",
                    minWidth: 200,
                    key: "taskDesc",
                    align: "center",
                },
                {
                    title: "关联业务点",
                    minWidth: 200,
                    key: "eventName",
                    align: "center",
                },
                {
                    title: "应用模式",
                    key: "poolName",
                    width: 170,
                    align: "center",
                },
                {
                    title: "所属类型",
                    key: "taskType",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.taskType && params.row.taskType != "" ? (params.row.taskType == "1" ? "获取" : params.row.taskType == "2" ? "扣除" : "") : "")
                    },
                },
                {
                    title: "显示在赚取积分栏",
                    key: "taskTab",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h("i-switch", {
                                props: {
                                    value: params.row.taskTab,
                                    size: "large",
                                    "true-value": "1",
                                    "false-value": "2",
                                },
                                scopedSlots: {
                                    open: () => h("span", "显示"),
                                    close: () => h("span", "隐藏"),
                                },
                                on: {
                                    "on-change": val => {
                                        this.changeSwitch(params.row.taskId, "tab")
                                    },
                                },
                            }),
                        ])
                    },
                },
                {
                    title: "启用状态",
                    key: "taskStatus",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h("i-switch", {
                                props: {
                                    value: params.row.taskStatus,
                                    size: "large",
                                    "true-value": "1",
                                    "false-value": "2",
                                },
                                scopedSlots: {
                                    open: () => h("span", "启用"),
                                    close: () => h("span", "禁用"),
                                },
                                on: {
                                    "on-change": val => {
                                        this.changeSwitch(params.row.taskId, "status")
                                    },
                                },
                            }),
                        ])
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    width: 200,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.selectTask(params.row.taskId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                // h(
                                //     "span",
                                //     {
                                //         on: {
                                //             click: async () => {
                                //                 this.deleteData(params.row.id)
                                //             },
                                //         },
                                //         style: {
                                //             width: "65px",
                                //             marginRight: "10px",
                                //             textAlign: "center",
                                //             color: "#2d8cf0",
                                //             cursor: "pointer",
                                //             userSelect: "none",
                                //         },
                                //     },
                                //     "删除" //1001
                                // ),
                            ]
                        )
                    },
                },
            ],
            currentFormData: {},
            validateForm: {
                taskName: [{ required: true, message: "请输入任务名称", trigger: "blur" }],
                // type: [{ required: true, type: "array", min: 1, message: "请选择服务类型", trigger: "change" }],
            },

            showImage: false,

            selectList: [],
            typeList: [
                {
                    dictValue: "获取",
                    dictKey: "1",
                },
                {
                    dictValue: "扣除",
                    dictKey: "2",
                },
            ],
            incomeList: [
                {
                    dictValue: "次数",
                    dictKey: "1",
                },
                {
                    dictValue: "金额",
                    dictKey: "2",
                },
                {
                    dictValue: "自定义",
                    dictKey: "3",
                },
            ],
            pointList: [],
            saveStatus: false,
            that: this,
            seletName: "",

            incomeName:'',

            poolId:''
        }
    },
    methods: {
        backClick() {
            var index = parent.layer.getFrameIndex(window.name)
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push({
                    path: "/mallhomepage",
                })
            }
        },
        setPoolId(val) {
            if (val && val != "") {
                this.selectList.map(item => {
                    if (item.poolId == val) this.seletName = item.poolName
                })
            }
        },
        changeIncome(val) {
            console.log('选择了',val);
            if (val && val != "") {
                this.currentFormData.sequenceNum = 0
                this.currentFormData.dayMaxCount = 0
                if(val == 1) this.incomeName = '次'
                else if(val == 2) this.incomeName = '元'
                else if(val == 3) {
                    this.incomeName = '次'
                    this.seletName = ''
                }
            }
        },
        selectTask(taskId) {
            this.$post("/gateway/sy-points/api/sypointes/pool/getTaskById", {
                taskId,
            }).then(res => {
                if (res.code == 200) {
                    this.currentFormData = res.data
                    if (this.currentFormData.poolId && this.currentFormData.poolId != "") {
                        this.selectList.map(item => {
                            if (item.poolId == this.currentFormData.poolId) this.seletName = item.poolName
                        })
                    }
                    this.saveStatusFn(true)
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.poolDesc,
                    })
                }
            })
        },
        //   切换状态
        changeSwitch(taskId, name) {
            this.$post("/gateway/sy-points/api/sypointes/pool/updateStatusOrTab", {
                taskId,
                updateFiled: name,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "操作成功",
                        })
                        this.currentFormData = {}
                        this.getList(this.page)
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.poolDesc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "操作失败",
                    })
                })
        },
        // 关闭弹窗
        saveStatusFn(status) {
            this.saveStatus = status
        },

        submit() {
            this.$refs.form.validate(status => {
                if (status) {
                    var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
                    var url = ""
                    if (cFormData.taskId && cFormData.taskId != "") {
                        // 修改
                        url = "/gateway/sy-points/api/sypointes/pool/updateTask"
                    } else {
                        // 新增
                        url = "/gateway/sy-points/api/sypointes/pool/addTask"
                    }
                    // 校验必填
                    this.$post(
                        url,
                        {
                            ...cFormData,
                            sequenceNum: cFormData.sequenceNum && cFormData.sequenceNum != "" ? Number(cFormData.sequenceNum) : 0,
                            dayMaxCount: cFormData.dayMaxCount && cFormData.dayMaxCount != "" ? Number(cFormData.dayMaxCount) : 0,
                        },
                        { "content-type": "application/json" }
                    )
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "保存成功",
                                })
                                this.getList(this.page)
                                this.saveStatusFn(false)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "保存失败，请联系管理员处理",
                            })
                        })
                }
            })
        },

        append() {
            // 新增
            this.currentFormData = {
                poolId: "", //积分池id
                taskName: "", //任务名称
                taskType: "", //任务类型；1：获取
                incomeType: "", //任务类型；1：获取
                seq: 1, //排序字段
                icon: "", //图标
                taskDesc: "", //任务描述
                herf: "", //跳转链接
                sequenceNum: 0, //每操作一次的积分数量
                dayMaxCount: 0, //每日操作的次数上限;不限次数默认99999
                eventId: "",
                coverRate: 100,
            }
            this.saveStatusFn(true)
        },
        // deleteData(id) {
        //     this.$Modal.confirm({
        //         title: "删除确认",
        //         content: "是否删除选中的数据？",
        //         onOk: () => {
        //             this.$post("/gateway/apps/o2o/api/o2o/test15min/delete", {
        //                 id,
        //             })
        //                 .then(res => {
        //                     if (res.code == 200) {
        //                         this.getList()
        //                     } else {
        //                         this.$Message.error({
        //                             background: true,
        //                             content: "删除出错：" + res.desc,
        //                         })
        //                     }
        //                 })
        //                 .catch(err => {
        //                     this.$Message.error({
        //                         background: true,
        //                         content: "删除出措",
        //                     })
        //                 })
        //         },
        //     })
        // },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.page = obj.page
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/gateway/sy-points/api/sypointes/pool/queryPoolTaskByPage", {
                page: this.page,
                pageSize: this.pageSize,
                poolId:this.$route.query.orgCode && this.$route.query.orgCode != '' ? this.poolId : this.searchModel.poolId,
                taskType:this.searchModel.taskType
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        async getSelectList() {
            await this.$get("/gateway/sy-points/api/sypointes/pool/listAllOption").then(res => {
                this.selectList = res
                this.selectList.map(item =>{
                    if(item.poolName == '线下积分兑换') this.poolId = item.poolId
                })

                if(this.$route.query.orgCode && this.$route.query.orgCode != ''){
                    this.selectList = this.selectList.filter(item => item.poolName == '线下积分兑换')
                }
            })
        },
        async getPointList() {
            await this.$get("/gateway/sy-points/api/sypointes/pool/queryEventByPage", {
                page: 1,
                pageSize: 1000,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.pointList = res.dataList
                    }
                })
                .catch(err => {})
        },
    },
    async created() {
        // 获取应用模式
        await this.getSelectList()
        // 获取任务点
        await this.getPointList()
        // 获取列表数据
        await this.getList()
    },
}
</script>

<style lang='less'>
.leftMenu {
    // position: fixed;
    // top: 60px;
    // left: 0px;
    width: calc(100% - 600px);
    // bottom: 0;
    background: #ffffff;
    // overflow: auto;
    float: left;
}
.rightEdit {
    float: left;
    // position: fixed;
    // top: 60px;
    height: 600px;
    width: 600px;
    // right: 0;
    bottom: 0;
    // overflow: auto;
    border-left: #ddd solid 1px;
}
.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
.ivu-breadcrumb {
    background-color: #fff;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
}
.form {
    padding: 10px 30px 10px 10px;
    height: 100%;
    overflow: scroll;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.toolsbarRight {
    display: flex;
    padding-top: 10px;
}
</style>
